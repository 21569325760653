import "../App.css";
import { useEffect, useState } from "react";

import Discord from "../icons/Discord";
import Roblox from "../icons/Roblox";
import Github from "../icons/Github";
import Instagram from "../icons/Instagram";
import Spotify from "../icons/Spotify";
import Steam from "../icons/Steam";
import Visualizer from "../components/Visualizer";

function Woe() {
    const [avatarUrl, setAvatarUrl] = useState("");
    const [title, setTitle] = useState("woe");
    const [description, setDescription] = useState(
        "too many days and too few hours"
    );
    const [isEntered, setIsEntered] = useState(false);

    useEffect(() => {
        const getAvatar = async () => {
            const res = await fetch(
                "https://ib2.dev/fetch.php?user=idb&type=avatar"
            );
            const url = await res.json();
            return url;
        };

        getAvatar().then((url) => {
            if (url) {
                setAvatarUrl(url);
            }
        });
    }, []);

    if (!isEntered) {
        return (
            <div class="mocha">
                <div
                    className="fixed inset-0 flex items-center justify-center bg-mantle cursor-pointer"
                    onClick={() => setIsEntered(true)} // Click to enter
                >
                    <h1 class="text-pink filter animate-pulse drop-shadow-[0_0_3.5px_rgb(245,194,231)] text-xl sm:text-xl md:text-xl lg:text-5xl">
                        &lt; click to enter &gt;
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div class="mocha">
            <div class="flex flex-col relative z-0 justify-center items-center h-screen bg-mantle custom-cursor">
                <div className="absolute inset-0 flex justify-center items-center">
                    <Visualizer />
                </div>

                <div class="flex flex-col justify-center items-center h-screen w-[42rem] max-w-[90%] max-h-[95%]">
                    <div class="bg-base/70 min-w-full min-h-[26rem] mb-4 overflow-hidden shadow-md shadow-crust backdrop-blur-md">
                        <div class="h-0.5 w-auto bg-[linear-gradient(100deg,_#cba6f7,_#f5c2e7,_#f9e2af,_#a6e3a1,_#89b4fa,_#b4befe)] animate-gradient"></div>

                        <div class="w-full h-[14rem] flex justify-center items-center">
                            <img
                                src={avatarUrl}
                                class="filter shadow-md shadow-crust w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full"
                                alt="avatar"
                            ></img>
                        </div>

                        <div class="w-full h-[12rem] flex flex-col items-center text-pink font-inter">
                            <h1 class="filter drop-shadow-[0_0_3.5px_rgb(245,194,231)] font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
                                {title}
                            </h1>
                            <p class="text-text filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] text-sm sm:text-md md:text-lg mt-2">
                                {description}
                            </p>

                            <div class="0 h-[5rem] w-full mt-auto flex flex-row justify-center items-center gap-5">
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="discord://-/users/226427306632151042"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Discord fill="#cdd6f4" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="https://www.roblox.com/users/516034734/profile"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Roblox fill="#cdd6f4" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="https://github.com/d29l"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Github fill="#cdd6f4" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="https://www.instagram.com/fx3d/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Instagram fill="#cdd6f4" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="https://open.spotify.com/user/31fniynopa4grbe56m7oacdj7kzu?si=b496a9f2d3a94627"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Spotify fill="#cdd6f4" />
                                </a>
                                <a
                                    class="filter drop-shadow-[0_0_3.5px_rgb(205,214,244)] w-[5%] aspect-square"
                                    href="https://steamcommunity.com/id/chudbf/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Steam fill="#cdd6f4" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Woe;
