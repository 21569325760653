import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router";
import Woe from "./pages/Woe";
import Gabe from "./pages/Gabe";
import Nisa from "./pages/Nisa";
import Zak from "./pages/Zak";
import Tyr from "./pages/Tyr"

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Woe />} />
                <Route path="/gabe" element={<Gabe/>} />
                <Route path="/aangelbite" element={<Nisa/>} />
                <Route path="/zk" element={<Zak/>} />
                <Route path="/tyr" element={<Tyr/>} />
            </Routes>
        </Router>
    );
}

export default App;
